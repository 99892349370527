import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const ServicesPage = () => {
  return (
    <Layout>
      <SEO title="Services" />
      <h1>Our Services</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolor
        eligendi enim explicabo laudantium libero maxime obcaecati, odit? Ea eos
        exercitationem, fugit id laboriosam maiores molestiae molestias neque
        non, officia quasi sit tempora velit? Aliquid deserunt libero quo saepe
        vitae!
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti
        distinctio dolorem doloribus esse est fugiat in magni mollitia non odio
        possimus qui, quo quos saepe, sit ut voluptates! Ab amet aperiam
        asperiores consequatur dignissimos dolor dolorem ea eaque enim ex
        expedita facilis fuga fugit hic illum in inventore ipsa iste magni
        maxime minima nemo non odit officia possimus quae quaerat quis, quod rem
        repellendus saepe similique soluta temporibus, vel vero, voluptas
        voluptates. Doloremque esse modi quidem quisquam, ratione sint sunt.
      </p>
      {/*<Link to="/page-2/">Go to page 2</Link>*/}
    </Layout>
  )
}

export default ServicesPage
